import { Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import { initial } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Image, Flex, Text } from 'rebass'
import AretaeioComingSoon from '../customPlugins/AretaeioComingSoon/AretaeioComingSoon'
import LoadingSpinner from '../customPlugins/LoadingSpinner/LoadingSpinner'
import FatalError from '../customPlugins/MayaErrors/FatalError'
import { ResponsibilityDisclaimerOverlay } from '../customPlugins/ResponsibilityDisclaimerOverlay/ResponsibilityDisclaimerOverlay'
import { useApplicationState } from '../hooks/useApplicationState'
import { useAutoResize } from '../hooks/useAutoResize'
import { useScrollToTop } from '../hooks/useScrollToTop'
import theme from '../theme'
import { AccountSetup } from './AccountSetup'
import { SetupCompleted } from './AccountSetup/SetupCompleted'
import { styles } from './appStyles'
import { Authentication } from './Authentication'
import { DomainConfigProvider } from './contexts/DomainConfigContext'
import DigitalHuman from './DigitalHuman'
import Home from './Home'
import { useDomainConfigContext } from './hooks/useDomainConfigContext'
import useIsBlacklisted from './hooks/useIsBlacklisted'
import SessionTimedOut from './SessionTimedOut'
import { StorageKeys } from './types/StorageKeys'

export type Steps =
  | 'digitalHuman'
  | 'timed-out'
  | 'home'
  | 'login'
  | 'accountSetup'
  | 'accountSetupEdit'
  | 'setupCompleted'
  | 'patientChooser'
  | 'setupCompleted'
  | 'editCompleted'
  | 'patientChooser'

const App = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const embeddedApp = Boolean(queryParams.get('embedded'))
  // param to skip the blacklist check
  const noBlackList = Boolean(queryParams.get('noBL'))
  const timeoutTestTime = Number(queryParams.get('test_timeout_time'))
  // @ts-ignore
  const { t } = useTranslation()
  const {
    state: { appIncludedSteps, showLogoOnFlow }
  } = useDomainConfigContext()

  const [speak, setSpeak] = useState(true)
  const {
    currentLanguage,
    disableDH,
    personaId,
    headingMessage,
    initError,
    logo,
    supportedLanguages,
    activeTheme,
    bgChatImage,
    bgImage,
    bgMobileImage,
    hideTranscript,
    hideDownloadNotes,
    loading,
    responsibilityDisclaimer,
    shouldAcceptTerms,
    userPhone,
    setUserPhone,
    userData,
    setUserData,
    setSid,
    sid,
    setPatientSelected,
    patientSelected,
    disableCB,
    phoneLoginDefaultString,
    initialStep,
    loadingMode
  } = useApplicationState({ embeddedApp })

  // For faster testing, skip straight to digitalHuman
  const [step, goTo] = useState<Steps>(
    appIncludedSteps.includes(initialStep || 'login')
      ? initialStep || 'login'
      : 'home'
  )

  useEffect(() => {
    initialStep && goTo(initialStep)
  }, [initialStep])

  useEffect(() => {
    const now = new Date()
    const userFromLS = localStorage.getItem(StorageKeys.API_USER_DATA)

    // if the item doesn't exist, return null
    if (!userFromLS) {
      return null
    }

    const userFromLSParsed = JSON.parse(userFromLS)

    // compare the expiry time of the item with the current time
    if (now.getTime() > userFromLSParsed.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(StorageKeys.API_USER_DATA)
      return null
    } else {
      setUserData(userFromLSParsed.value)
      goTo('home')
    }
  }, [setUserData])

  const restart = () => goTo('home')

  const stepIsDigitalHuman = useCallback(() => step === 'digitalHuman', [step])

  const goToPatientChooser = () => goTo('patientChooser')

  const height = useAutoResize({ condition: stepIsDigitalHuman })
  useScrollToTop({ condition: stepIsDigitalHuman })

  const finalTheme = theme(activeTheme, embeddedApp)

  const isBlacklisted = useIsBlacklisted(noBlackList)

  if (isBlacklisted && !noBlackList) {
    return (
      <ThemeProvider theme={finalTheme}>
        <AretaeioComingSoon
          logo={logo}
          languageSelected={currentLanguage}
          supportedLanguages={supportedLanguages}
        />
      </ThemeProvider>
    )
  }

  if (loading) {
    return (
      <ThemeProvider theme={finalTheme}>
        <Box sx={styles.loadingSpinner}>
          <LoadingSpinner />
        </Box>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={finalTheme}>
      {responsibilityDisclaimer && (
        <ResponsibilityDisclaimerOverlay
          disclaimerImage={
            responsibilityDisclaimer.responsibilityDisclaimerImage
          }
          disclaimerText={responsibilityDisclaimer.responsibilityDisclaimerText}
        />
      )}
      <Global styles={theme => ({ body: { fontFamily: theme.fonts.body } })} />
      <Box
        variant="mainAppContainer"
        sx={{
          height: height || '100%',
          overflow: step === 'home' ? 'visible' : 'hidden',
          ...(bgImage && (step === 'home' || step === 'patientChooser')
            ? finalTheme.appStyles.appBackground(bgImage, bgMobileImage)
            : {}),
          ...(bgChatImage && (step === 'digitalHuman' || step === 'timed-out')
            ? finalTheme.appStyles.chatBackground(bgChatImage)
            : {})
        }}
      >
        {showLogoOnFlow && (
          <Box variant="homeLogo">
            <Image src={logo} alt="Logo"></Image>
          </Box>
        )}
        {initError && (
          <FatalError
            errorTitle={t('Error.serverError')}
            errorMessage={initError}
            clearError={() => window.location.reload()}
            buttonText={t('Error.tryAgain')}
          />
        )}
        {appIncludedSteps.includes('digitalHuman') &&
        step === 'digitalHuman' ? (
          <DigitalHuman
            speak={speak}
            onTimedOut={() => goTo('timed-out')}
            restart={restart}
            onSessionEnded={() => {}}
            embeddedMode={embeddedApp}
            personaId={personaId}
            testTimeoutTime={timeoutTestTime}
            hideTranscript={hideTranscript}
            hideDownloadNotes={hideDownloadNotes}
            session={sid}
            patientSelectedId={patientSelected?._id}
            preAuthToken={userData && userData[0].preAuthToken}
            loadingMode={loadingMode}
          />
        ) : appIncludedSteps.includes('timed-out') && step === 'timed-out' ? (
          <SessionTimedOut restart={restart} />
        ) : (appIncludedSteps.includes('home') && step === 'home') ||
          (appIncludedSteps.includes('patientChooser') &&
            step === 'patientChooser') ? (
          <Home
            embeddedMode={embeddedApp}
            startSession={speak => {
              setSpeak(speak)
              goTo('digitalHuman')
            }}
            restart={() => goTo('home')}
            headingMessage={headingMessage}
            disableDigitalHuman={disableDH}
            disableChatBot={disableCB}
            logo={logo}
            languageSelected={currentLanguage}
            supportedLanguages={supportedLanguages}
            showVideoBackground={bgImage === null}
            shouldAcceptTerms={shouldAcceptTerms}
            patientSelector={appIncludedSteps.includes('accountSetup')}
            userData={userData}
            setSid={setSid}
            setPatientSelected={setPatientSelected}
            patientSelected={patientSelected}
            onManageFamilyMembers={() => goTo('accountSetupEdit')}
          />
        ) : (appIncludedSteps.includes('setupCompleted') &&
            step === 'setupCompleted') ||
          (appIncludedSteps.includes('editCompleted') &&
            step === 'editCompleted') ? (
          <SetupCompleted
            onSuccess={() =>
              goTo(step === 'editCompleted' ? 'patientChooser' : 'home')
            }
          />
        ) : (appIncludedSteps.includes('accountSetup') &&
            step === 'accountSetup') ||
          (appIncludedSteps.includes('accountSetupEdit') &&
            step === 'accountSetupEdit') ? (
          <AccountSetup
            userData={userData}
            setUserData={setUserData}
            userPhone={userPhone}
            onSuccess={() =>
              goTo(
                step === 'accountSetupEdit' ? 'editCompleted' : 'setupCompleted'
              )
            }
            onBackButton={
              appIncludedSteps.includes('accountSetupEdit') &&
              step === 'accountSetupEdit'
                ? goToPatientChooser
                : undefined
            }
          />
        ) : appIncludedSteps.includes('login') && step === 'login' ? (
          <Authentication
            onRegisteredUserLogin={() => goTo('patientChooser')}
            onUnregisteredUserLogin={() => goTo('accountSetup')}
            supportedLanguages={supportedLanguages}
            languageSelected={currentLanguage}
            setUserPhone={setUserPhone}
            setUserData={setUserData}
            phoneLoginDefaultString={phoneLoginDefaultString}
          />
        ) : null}
      </Box>
    </ThemeProvider>
  )
}

const AppWrapper = () => {
  return (
    <DomainConfigProvider>
      <App />
    </DomainConfigProvider>
  )
}

export default AppWrapper
